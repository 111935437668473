import React from 'react';
import { Box, Container, Typography } from '@mui/material';
import HeroImage from '../../assets/LandingImage.png'; // Correctly import the image

const HeroSection = () => {
  return (
    <Box
      sx={{
        fontFamily: 'monospace',
        height: '70vh', // Full viewport height
        paddingTop: '2rem',
        display: 'flex', // Use flexbox for centering
        flexDirection: 'column', // Stack content vertically
        textAlign: 'center', // Center the text inside
        backgroundImage: `url(${HeroImage})`, // Correctly reference the imported image
        backgroundSize: 'cover', // Ensure background image covers entire container
        backgroundPosition: 'center', // Center the background image
        color: '#fff', // White text color for contrast
        px: 2, // Padding for small screens
      }}
    >
       <Container maxWidth="lg">
        <Typography variant="h4" sx={{ fontWeight: 'bold', mb: 2 }}>
        India's First Exclusive Recruitment Company for BPO Companies
        </Typography>
        <Typography variant="h6" sx={{ mb: 4 }}>
        Driving excellence with premier BPO talent to build the future of customer service and business operations.
        </Typography>
      </Container>
    </Box>
  );
};

export default HeroSection;
